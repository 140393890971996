import React from 'react'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'

const InfoIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox="0 0 16 16"
  >
    <path
      fill={defaultTheme.ColorBaseMineShaft500}
      fillRule="evenodd"
      d="M8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8 .667 3.95 3.95.667 8 .667zm0 1.466C4.76 2.133 2.133 4.76 2.133 8S4.76 13.867 8 13.867 13.867 11.24 13.867 8 11.24 2.133 8 2.133zm.167 5.2c.46 0 .833.329.833.734V11c0 .405-.373.733-.833.733-.46 0-.834-.328-.834-.733V8.067c0-.405.373-.734.834-.734zm0-2.666c.46 0 .833.373.833.833 0 .46-.373.833-.833.833-.46 0-.834-.373-.834-.833 0-.46.373-.833.834-.833z"
    />
  </svg>
)

export default InfoIcon
