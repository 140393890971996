import { datadogRum } from '@datadog/browser-rum-slim'

export const DATADOG_FLOW_NAMES = {
  PAYMENT: 'payment',
  CHECKOUT: 'checkout',
  DY_INIT_API: 'dyInitApi',
}

export const addDatadogFlowError = (error, flowName) => {
  datadogRum.addError(error, {
    'flow': flowName,
  })
}
