import React from 'react'

function SvgCloseIcon({ title, titleId, color, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill={color || '#333'}
        fillRule="evenodd"
        d="M7.293 7.293a1 1 0 011.414 0L12 10.585l3.293-3.292a1 1 0 011.414 1.414L13.415 12l3.292 3.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.414 0L12 13.415l-3.293 3.292a1 1 0 11-1.414-1.414L10.585 12 7.293 8.707a1 1 0 01-.083-1.32z"
      />
    </svg>
  )
}

export default SvgCloseIcon
