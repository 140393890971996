import { GTM_ENV_VAR, PAGE_TYPES } from '../../../lib/eventTrackerFunction'
import {
  AMP_EVENT_NAME as AEN,
  CTA_LOCATION_NAME,
} from '../../../lib/amplitude'

const TRACKER_CONSTANTS = {
  /* E-Voucher */
  LANDED_EVOUCHER: 'landed_evoucher',
  ON_EVOUCHER_STORE_VOUCHER_BTN_CLICK: 'on_evoucher_store_voucher_btn_click',
  ON_EVOUCHER_COPY_CODE: 'on_evoucher_copy_code',
  ON_EVOUCHER_TRANSACTION_HISTORY_CLICKED:
    'on_evoucher_transaction_history_clicked',
  ON_EVOUCHER_HOW_TO_REDEEM_CLICKED: 'on_evoucher_how_to_redeem_clicked',
  ON_EVOUCHER_WHERE_TO_REDEEM_CLICKED: 'on_evoucher_where_to_redeem_clicked',
  ON_EVOUCHER_TNC_CLICKED: 'on_evoucher_tnc_clicked',
  ON_EVOUCHER_HELP_CLICKED: 'on_evoucher_help_clicked',
  /* E-Voucher */

  /* Voucher Wallet */
  LANDED_VOUCHER_WALLET: 'landed_voucher_wallet',
  ON_VOUCHER_WALLET_PROMOCODE_DETAIL: 'on_voucher_wallet_promocode_detail',
  ON_VOUCHER_WALLET_EVOUCHER_DETAIL: 'on_voucher_wallet_evoucher_detail',
  ON_VOUCHER_WALLET_USE_NOW_CLICK: 'on_voucher_wallet_use_now_click',
  ON_VOUCHER_WALLET_HOW_TO_USE_CLICK: 'on_voucher_wallet_how_to_use_click',
  ON_VOUCHER_WALLET_ACCOUNT_VOUCHERS_TAB_CLICKED:
    'on_voucher_wallet_account_vouchers_tab_clicked',
  ON_VOUCHER_WALLET_FILTERBY_CLICKED: 'ON_VOUCHER_WALLET_FILTERBY_CLICKED',
  ON_VOUCHER_WALLET_FILTERBY_OPTION_CLICKED:
    'on_voucher_wallet_filterby_option_clicked',
  /* Voucher Wallet */

  /* Cart */
  ON_ADD_VOUCHER_CART_CLICKED: 'on_add_voucher_cart_clicked',
  /* Cart */

  /* Checkout */
  ON_ADD_VOUCHER_CHECKOUT_CLICKED: 'on_add_voucher_checkout_clicked',
  /* Checkout */

  /* Voucher Code */
  ON_ADD_VOUCHER_CODE_INPUT: 'on_add_voucher_code_input',
  ON_VOUCHER_CODE_SELECT: 'on_voucher_code_select',
  ON_ADD_VOUCHER_CODE_SUCCESSFUL: 'on_add_voucher_code_successful',
  ON_ADD_VOUCHER_CODE_UNSUCCESSFUL: 'on_add_voucher_code_unsuccessful',
  ON_ADD_VOUCHER_CODE_INPUT_SUCCESSFUL: 'on_add_voucher_code_input_successful',
  ON_ADD_VOUCHER_CODE_INPUT_UNSUCCESSFUL:
    'on_add_voucher_code_input_unsuccessful',
  ON_SELECT_ALL_VOUCHER_CODE_IN_CART: 'on_select_all_voucher_code_in_cart',
  ON_SELECT_ALL_VOUCHER_CODE_IN_CHECKOUT:
    'on_select_all_voucher_code_in_checkout',
  /* Voucher Code */

  /* Data Science Team tracking */
  TRACK_PARAM:
    '{%7B%22exp%22%3A%22Z%22%2C%22model%22%3A%22r4u%23baseline_v2-u2o%230%22%7D}',
  /* Data Science Team tracking */
}

const VOUCHERS_COMMON_ATTRS = {
  [GTM_ENV_VAR.EVENT]: 'hm_push_event',
}

const EVOUCHER_COMMON_ATTRS = {
  ...VOUCHERS_COMMON_ATTRS,
  [GTM_ENV_VAR.PAGE_TYPE]: PAGE_TYPES.EVOUCHER,
  [GTM_ENV_VAR.EVENT_CAT]: 'Voucher_E-Voucher_URL',
  [GTM_ENV_VAR.SCREEN_NAME]: 'E-Voucher URL Page',
}
const VOUCHER_WALLET_COMMON_ATTRS = {
  ...VOUCHERS_COMMON_ATTRS,
  [GTM_ENV_VAR.PAGE_TYPE]: PAGE_TYPES.VOUCHER_WALLET,
  [GTM_ENV_VAR.EVENT_CAT]: 'Voucher_Wallet',
}
const VOUCHER_WALLET = 'Voucher Wallet'

const VOUCHER_CODE_COMMON_ATTRS = {
  ...VOUCHERS_COMMON_ATTRS,
  [GTM_ENV_VAR.PAGE_TYPE]: PAGE_TYPES.VOUCHER_CODE,
  [GTM_ENV_VAR.EVENT_CAT]: 'Voucher_Code_Cart_Checkout',
  [GTM_ENV_VAR.SCREEN_NAME]: 'Cart_Checkout_Voucher_Code',
  [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
}

const eventsDataset = {
  /* E-Voucher */
  [TRACKER_CONSTANTS.LANDED_EVOUCHER]: {
    ...EVOUCHER_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Landed',
    [GTM_ENV_VAR.EVENT_LABEL]: 'EVoucher-URL-Page',
  },
  [TRACKER_CONSTANTS.ON_EVOUCHER_STORE_VOUCHER_BTN_CLICK]: {
    ...EVOUCHER_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'EVoucher-URL-Page_Store_Voucher',
  },
  [TRACKER_CONSTANTS.ON_EVOUCHER_COPY_CODE]: {
    ...EVOUCHER_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'EVoucher-URL-Page_Copy_Code',
  },
  [TRACKER_CONSTANTS.ON_EVOUCHER_TRANSACTION_HISTORY_CLICKED]: {
    ...EVOUCHER_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'EVoucher-URL-Page_Transaction_History',
  },
  [TRACKER_CONSTANTS.ON_EVOUCHER_HOW_TO_REDEEM_CLICKED]: {
    ...EVOUCHER_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'EVoucher-URL-Page_How_Redeem',
  },
  [TRACKER_CONSTANTS.ON_EVOUCHER_WHERE_TO_REDEEM_CLICKED]: {
    ...EVOUCHER_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'EVoucher-URL-Page_Where_Redeem',
  },
  [TRACKER_CONSTANTS.ON_EVOUCHER_TNC_CLICKED]: {
    ...EVOUCHER_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'EVoucher-URL-Page_TnC',
  },
  [TRACKER_CONSTANTS.ON_EVOUCHER_HELP_CLICKED]: {
    ...EVOUCHER_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'EVoucher-URL-Page_Help',
  },
  /* E-Voucher */
  /* Voucher Wallet */
  [TRACKER_CONSTANTS.LANDED_VOUCHER_WALLET]: {
    ...VOUCHER_WALLET_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Landed',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Voucher-Wallet',
    [GTM_ENV_VAR.SCREEN_NAME]: VOUCHER_WALLET,
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },
  [TRACKER_CONSTANTS.ON_VOUCHER_WALLET_PROMOCODE_DETAIL]: {
    ...VOUCHER_WALLET_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Voucher-Wallet_Promocode_Detail',
    [GTM_ENV_VAR.SCREEN_NAME]: VOUCHER_WALLET,
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },
  [TRACKER_CONSTANTS.ON_VOUCHER_WALLET_EVOUCHER_DETAIL]: {
    ...VOUCHER_WALLET_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Voucher-Wallet_EVoucher_Detail',
    [GTM_ENV_VAR.SCREEN_NAME]: VOUCHER_WALLET,
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },
  [TRACKER_CONSTANTS.ON_VOUCHER_WALLET_USE_NOW_CLICK]: {
    ...VOUCHER_WALLET_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Voucher-Wallet_EVoucher_Use',
    [GTM_ENV_VAR.SCREEN_NAME]: 'E-Voucher Detail',
  },
  [TRACKER_CONSTANTS.ON_VOUCHER_WALLET_HOW_TO_USE_CLICK]: {
    ...VOUCHER_WALLET_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Voucher-Wallet_EVoucher_Help',
    [GTM_ENV_VAR.SCREEN_NAME]: 'E-Voucher QR',
  },
  [TRACKER_CONSTANTS.ON_VOUCHER_WALLET_ACCOUNT_VOUCHERS_TAB_CLICKED]: {
    ...VOUCHER_WALLET_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.SCREEN_NAME]: 'Account',
  },
  [TRACKER_CONSTANTS.ON_VOUCHER_WALLET_FILTERBY_CLICKED]: {
    ...VOUCHER_WALLET_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Voucher-Wallet_FilterBy',
    [GTM_ENV_VAR.SCREEN_NAME]: VOUCHER_WALLET,
  },
  [TRACKER_CONSTANTS.ON_VOUCHER_WALLET_FILTERBY_OPTION_CLICKED]: {
    ...VOUCHER_WALLET_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.SCREEN_NAME]: VOUCHER_WALLET,
  },
  /* Voucher Wallet */
  /* Cart */
  [TRACKER_CONSTANTS.ON_ADD_VOUCHER_CART_CLICKED]: {
    ...VOUCHERS_COMMON_ATTRS,
    [GTM_ENV_VAR.PAGE_TYPE]: PAGE_TYPES.CART,
    [GTM_ENV_VAR.EVENT_CAT]: 'Voucher_Code_Cart_Checkout',
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Cart-Add_Voucher',
    [GTM_ENV_VAR.SCREEN_NAME]: 'Order-Cart',
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },
  /* Cart */
  /* Checkout */
  [TRACKER_CONSTANTS.ON_ADD_VOUCHER_CHECKOUT_CLICKED]: {
    ...VOUCHERS_COMMON_ATTRS,
    [GTM_ENV_VAR.PAGE_TYPE]: PAGE_TYPES.CHECKOUT,
    [GTM_ENV_VAR.EVENT_CAT]: 'Voucher_Code_Cart_Checkout',
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Checkout-Add_Voucher',
    [GTM_ENV_VAR.SCREEN_NAME]: 'Order-Checkout',
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },
  /* Checkout */
  /* Voucher Code */
  [TRACKER_CONSTANTS.ON_ADD_VOUCHER_CODE_INPUT]: {
    ...VOUCHER_CODE_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Cart-Add_Voucher_Input',
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },
  [TRACKER_CONSTANTS.ON_VOUCHER_CODE_SELECT]: {
    ...VOUCHER_CODE_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Cart-Add_Voucher_Select',
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },
  [TRACKER_CONSTANTS.ON_ADD_VOUCHER_CODE_SUCCESSFUL]: {
    ...VOUCHER_CODE_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Cart-Add_Voucher_Successful',
    [GTM_ENV_VAR.AMPLITUDE_EVENT_NAME]: AEN.VOUCHER_CODE_ADDED,
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
    [GTM_ENV_VAR.AMPLITUDE_CTA_LOCATION]: {
      pageName: CTA_LOCATION_NAME.CHECKOUT,
    },
  },
  [TRACKER_CONSTANTS.ON_ADD_VOUCHER_CODE_UNSUCCESSFUL]: {
    ...VOUCHER_CODE_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Cart-Add_Voucher_Unsuccessful',
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },
  [TRACKER_CONSTANTS.ON_ADD_VOUCHER_CODE_INPUT_SUCCESSFUL]: {
    ...VOUCHER_CODE_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Cart-Add_Voucher_Input_Successful',
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },
  [TRACKER_CONSTANTS.ON_ADD_VOUCHER_CODE_INPUT_UNSUCCESSFUL]: {
    ...VOUCHER_CODE_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'Cart-Add_Voucher_Input_Unsuccessful',
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },
  [TRACKER_CONSTANTS.ON_SELECT_ALL_VOUCHER_CODE_IN_CART]: {
    ...VOUCHER_CODE_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'cart-add_voucher_select_all',
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },
  [TRACKER_CONSTANTS.ON_SELECT_ALL_VOUCHER_CODE_IN_CHECKOUT]: {
    ...VOUCHER_CODE_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'checkout-add_voucher_select_all',
    [GTM_ENV_VAR.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
  },

  /* Voucher Code */
}

export { GTM_ENV_VAR, TRACKER_CONSTANTS, eventsDataset }
