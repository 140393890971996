export const CONNECTION_STATUSES = {
  connected: 'connected',
  notConnected: 'not_connected',
  legacyFull: 'legacy_full',
  legacyPartial: 'legacy_partial',
}

export const apiStatusConstants = apiStatus => {
  switch (apiStatus) {
    case CONNECTION_STATUSES.notConnected:
      return CONNECTION_STATUSES.notConnected
    case CONNECTION_STATUSES.legacyPartial:
      return CONNECTION_STATUSES.legacyPartial
    case CONNECTION_STATUSES.legacyFull:
      return CONNECTION_STATUSES.legacyFull
    default:
      return CONNECTION_STATUSES.connected
  }
}
