import React from 'react'
import styled, { keyframes } from 'styled-components'

const pulseAnimation = keyframes`
    0% {
        opacity: 0.5;
        transform: scale(1);
    }
    
    50% {
        opacity: 1;
        transform: scale(1.25);
    }
    
    100% {
        opacity: 0.5;
        transform: scale(1);
    }
`

const LoaderDots = styled.span`
  display: inline-block;
  width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  margin: ${({ size }) => `${Number(size) / 2.5}px`};
  border-radius: ${({ size }) => `${Number(size) / 2.5}px`};
  background-color: ${({ bgColor }) => bgColor};
  will-change: transform, opacity;
  animation: ${pulseAnimation} 1.25s infinite ease;
  animation-delay: ${({ animationDelay }) => animationDelay + 'ms'};
`

const DotLoader = ({ size, bgColor, dataTestid }) => (
  <div data-testid={dataTestid ? dataTestid : 'dotLoader'}>
    <LoaderDots size={size} bgColor={bgColor} animationDelay={250} />
    <LoaderDots size={size} bgColor={bgColor} animationDelay={500} />
    <LoaderDots size={size} bgColor={bgColor} animationDelay={750} />
  </div>
)

DotLoader.defaultProps = {
  size: 10,
  bgColor: '#cccccc',
}

export default DotLoader
