import { getExpConfig } from './configService'
import { SPLIT_FEATURES } from './constants'

const boysBrigadeConfigFn = () => {
  const bbDataSplit = getExpConfig(SPLIT_FEATURES.BOYS_BRIGADE) || {}
  try {
    const boysBrigadeConfig = JSON.parse(bbDataSplit?.config || '{}')
    return { ...boysBrigadeConfig }
  } catch (err) {
    return {}
  }
}

const getBBConfigState = isBoysBrigade => {
  const boysBrigadeConfig = boysBrigadeConfigFn()
  const bannerText = boysBrigadeConfig?.bannerText
  const isBoysBrigadeEnabled = !!(boysBrigadeConfig?.enabled && isBoysBrigade)
  return { isBoysBrigadeEnabled, bannerText }
}

module.exports = {
  boysBrigadeConfigFn,
  getBBConfigState,
}
